<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-alert
          border="left"
          colored-border
          :color="colors.deepPurple.accent4"
          elevation="2"
        >
          Здесь можно настроить шаблон поста, который будет отправлен новому подписчику после того как бот одобрит его заявку.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <v-text-field v-model="post.title" placeholder="Название велком-поста" label="Название велком-поста" outlined>
              <template v-slot:append-outer>
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      {{mdiInformationOutline}}
                    </v-icon>
                  </template>
                  Никада не сдавайся и не забывай про название поста брат
                </v-tooltip>
              </template>
            </v-text-field>
            <editor :model-value.sync="post.text" :text-length.sync="post.length" type="ad-welcome"></editor>
            <v-row dense>
              <v-col cols="12">
                <v-checkbox v-model="post.addButton" label="Добавить кнопку"></v-checkbox>
              </v-col>
              <v-col v-if="post.addButton" cols="12">
                <v-col cols="6">
                  <v-text-field v-model="post.button_text" placeholder="Текст в кнопке" label="Текст в кнопке" outlined></v-text-field>
                  <v-text-field v-model="post.button_link" placeholder="Ссылка в кнопке" label="Ссылка в кнопке" outlined></v-text-field>
                </v-col>

              </v-col>
              <v-col cols="6">
                <v-radio-group v-model="post.tg_method">
                  <v-radio :disabled="post.length>1024" value="sendPhoto" label="Фото сверху"></v-radio>
                  <v-radio value="sendMessage" label="Фото снизу"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="savePost()">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="post.image" cols="6">
        <v-img :src="`https://backend.telestatic.ru/upload/ad_welcome_images/${post.image}`"></v-img>
        <v-btn class="mt-3" color="error" @click="deleteImage()">Поменять картинку</v-btn>
      </v-col>
      <v-col v-else cols="6">
        <vue2-dropzone
          id="dropzone"
          ref="dropzone"
          class="mb-3"
          :options="dropzoneOptions"
          @vdropzone-success="uploadSuccess"
          @vdropzone-file-added="updateDZHeaders"
        ></vue2-dropzone>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import {mdiInformationOutline} from '@mdi/js'
import httpClient from '@/services/http.service'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'AdWelcomePosts',
  components:{
    editor: () => import('@/components/posts/Editor'),
    vue2Dropzone
  },
  data(){
    return{
      colors,
      mdiInformationOutline,
      post: {},
      dropzoneOptions: {
        autoProcessQueue: true,
        url: 'https://backend.telestatic.ru/upload/ad-welcomes?id=0',
        thumbnailWidth: 150,
        maxFilesize: 100,
      },
    }
  },
  created() {
    if (this.$route.params.id > 0){
      httpClient.get(`/ad-welcomes/${this.$route.params.id}`).then(({data})=>{
        this.post = data
      })
    }
  },
  methods:{
    savePost(){
      if (this.post.id < 1){
        httpClient.post('ad-welcomes', this.post).then(result=>{
          console.log('ad post save result: ', result)
          if (result.status === 201){
            this.$toast('Пост сохранён, загружаю картинку...')
            this.$refs.dropzone.setOption('url', `https://backend.telestatic.ru/upload/ad-welcome?id=${result.data.id}`)
            this.$refs.dropzone.processQueue()
          }else{
            this.$toast.error('Не удалось сохранить пост!')
          }
        })
      }else{
        httpClient.put(`ad-welcomes/${this.post.id}`, this.post).then(result=>{
          console.log('ad post save result: ', result)
          if (result.status === 200){
            this.$toast('Пост сохранён')
          }else{
            this.$toast.error('Не удалось сохранить пост!')
          }
        })
      }
    },
    deleteImage(){
      this.post.image = false
    },
    uploadSuccess(file, response){
      this.$toast.success('Картинка - загружена, ты - молодец!')
      this.post.image = response
    },
    updateDZHeaders(){
      console.log('update dz headers')
      this.$refs.dropzone.setOption('url', `https://backend.telestatic.ru/upload/ad-welcome?id=${this.post.id}`)
      this.$refs.dropzone.setOption('headers', { Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` })
    },
  }
}
</script>

<style scoped>

</style>
